const aspectRatios = {
  '1x1': {
    mobile: '1x1',
    desktop: '1x1',
  },
  '1x1-card': {
    mobile: '1x1-card',
    desktop: '1x1-card',
  },
  '2x1': {
    mobile: '2x1',
    desktop: '2x1',
  },
  '2x3': {
    mobile: '2x3',
    desktop: '2x3',
  },
  '3x2': {
    mobile: '3x2',
    desktop: '3x2',
  },
  '3x2-m': {
    mobile: '3x2-m',
    desktop: '3x2-m',
  },
  '3x2-card': {
    mobile: '3x2-card',
    desktop: '3x2-card',
  },
  '3x4-card': {
    mobile: '3x4-card',
    desktop: '3x4-card',
  },
  '4x3': {
    mobile: '4x3',
    desktop: '4x3',
  },
  '4x5': {
    mobile: '4x5',
    desktop: '4x5',
  },
  '4x5-m': {
    mobile: '4x5-m',
    desktop: '4x5-m',
  },
  '5x2': {
    mobile: '5x2',
    desktop: '5x2',
  },
  '5x4': {
    mobile: '5x4',
    desktop: '5x4',
  },
  '5x4-m': {
    mobile: '2x4',
    desktop: '5x4',
  },
  '5x4-large': {
    mobile: '5x4-large',
    desktop: '5x4-large',
  },
  '16x9': {
    mobile: '16x9',
    desktop: '16x9',
  },
  '16x9-m': {
    mobile: '16x9-m',
    desktop: '16x9-m',
  },
  '16x6': {
    mobile: '16x9',
    desktop: '16x6',
  },
  '2x1-m': {
    mobile: '3x2',
    desktop: '2x1',
  },
};

export default aspectRatios;
